<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
        <template v-if="formData.recruitment_delears && Object.keys(formData.recruitment_delears).length > 0">
          <b-col sm="12">
              <table class="table table-sm table-bordered section-tree-view-table">
                <thead>
                  <tr>
                    <th class="text-center font-weight-bold">{{$t('globalTrans.division')}}</th>
                    <th class="text-center font-weight-bold">{{$t('globalTrans.district')}}</th>
                    <th class="text-center font-weight-bold">{{$t('globalTrans.upazila')}}</th>
                    <th class="text-center font-weight-bold">{{$t('globalTrans.city_corporation')}}</th>
                    <th class="text-center font-weight-bold">{{$t('globalTrans.pouroshova')}}</th>
                    <th class="text-center font-weight-bold">{{$t('globalTrans.union')}}</th>
                    <th class="text-center font-weight-bold">{{$t('globalTrans.ward')}}</th>
                    <th class="text-center font-weight-bold">{{$t('license_management.num_of_dealer')}}</th>
                  </tr>
                </thead>
                  <tbody>
                      <slot v-for="(item, index) in formData.recruitment_delears">
                        <tr :key="index">
                          <td class="text-center">
                            <span v-if="item.upazila_id">{{ getUnionDivisionName(item.upazila_id) }}</span>
                            <span v-else-if="item.division_id">{{ getDivisionName(item.division_id) }}</span>
                            <span v-else>-</span>
                          </td>
                          <td class="text-center">
                              <span v-if="item.upazila_id">{{ getUnionDistrictName(item.upazila_id) }}</span>
                              <span v-else-if="item.district_id">{{ getDistrictName(item.district_id) }}</span>
                              <span v-else>-</span>
                          </td>
                          <td class="text-center">
                              {{item.upazila_id ? getUpazilaName(item.upazila_id) : '-'}}
                          </td>
                          <td class="text-center">
                              {{item.city_corporation_id ? getCityCorporationName(item.city_corporation_id) : '-' }}
                          </td>
                          <td class="text-center">
                              {{item.pauroshoba_id ? getPauroshobaName(item.pauroshoba_id) : '-'}}
                          </td>
                          <td class="text-center">
                              {{item.union_id ? getUnionName(item.union_id) : '-'}}
                          </td>
                          <td class="text-center">
                              {{ item.ward_id ? getWardName(item.ward_id) : '-' }}
                          </td>
                          <td style="width: 20%" class="text-center">
                            <ValidationProvider name="Number of Dealer" :vid="`item.num_of_dealer-${index}`"  rules="required|min_value:1">
                                <b-form-group
                                  label-for="num_of_dealer"
                                  slot-scope="{ valid, errors }"
                                >
                                <b-form-input
                                  type="number"
                                  :readonly="approve_status === 2 ? true : false"
                                  v-model.number="item.num_of_dealer"
                                  @keypress="isNumber"
                                  min="0"
                                  :id="'num_of_dealer-' + index"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                                </b-form-group>
                              </ValidationProvider>
                          </td>
                        </tr>
                      </slot>
                  </tbody>
              </table>
          </b-col>
        </template>
        <b-row class="text-right">
          <b-col>
              <b-button v-if="approve_status !== 2" type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form-delear')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { recruitmentDelearStore } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'
export default {
  name: 'DelearEntryForm',
  components: {
  },
  props: ['id', 'recruitment_delears', 'approve_status'],
  data () {
    return {
      valid: null,
      saveBtnName: this.recruitment_delears.length > 0 ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      formData: {
        tcb_recruitment_notice_id: null,
        recruitment_delears: []
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getRecruitmentNotice()
      this.formData.tcb_recruitment_notice_id = tmp.id
      if (tmp.recruitment_delears && tmp.recruitment_delears.length > 0) {
        this.formData.recruitment_delears = tmp.recruitment_delears
      } else {
        if (tmp.union_id && tmp.union_id.length > 0) {
          tmp.union_id.map((item) => {
            var obj = Object.assign({}, { ward_id: 0, union_id: item }, this.setUnionData(item))
            this.formData.recruitment_delears.push(obj)
        })
        }
        if (tmp.ward_id && tmp.ward_id.length > 0) {
          tmp.ward_id.map((item) => {
            var obj = Object.assign({}, { union_id: 0, ward_id: item }, this.setWardData(item))
            this.formData.recruitment_delears.push(obj)
        })
        }
      }
    }
  },
  watch: {
    // 'formData.area_type_id': function (newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.formData.division_id = []
    //   }
    // },
    'formData.division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getMultipleDistrictList(newVal)
      }
    },
    'formData.district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getMultipleUpazilaList(newVal)
        this.getMultipleCityCorporationList(newVal)
      }
    },
    'formData.upazila_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getMultiplePauroshabaList(newVal)
        this.getMultipleUnionList(newVal)
      }
    },
    'formData.city_corporation_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getMultipleWordList()
      }
    },
    'formData.pauroshoba_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getMultipleWordList()
      }
    },
    'formData.union_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getMultipleWordList()
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    areaTypeList: function () {
      const list = this.$store.state.commonObj.areaTypeList
      return list.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    divisionList: function () {
      return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
    }
  },
  methods: {
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      result = await RestApi.postData(licenseRegistrationServiceBaseUrl, recruitmentDelearStore, this.formData)

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('LicenseRegistrationService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form-delear')
      } else {
        this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: result.message,
          color: '#ee5253'
        })
        this.$refs.form.setErrors(result.errors)
      }
    },
    getRecruitmentNotice () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    isNumber (evt) {
      helpers.isNumber(evt)
    },
    getWardName (id) {
      const obj = this.$store.state.CommonService.commonObj.wardList.find(item => item.value === id)
      if (obj !== 'undefined') {
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      }
    },
    getUnionName (id) {
      const obj = this.$store.state.CommonService.commonObj.unionList.find(item => item.value === id)
      if (obj !== 'undefined') {
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      }
    },
    getUpazilaName (id) {
      const obj = this.$store.state.CommonService.commonObj.upazilaList.find(item => item.value === id)
      if (obj !== 'undefined') {
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      }
    },
    getCityCorporationName (id) {
      const obj = this.$store.state.CommonService.commonObj.cityCorporationList.find(item => item.value === id)
      if (obj !== 'undefined') {
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      }
    },
    getDivisionName (id) {
      const obj = this.$store.state.CommonService.commonObj.divisionList.find(item => item.value === id)
      if (obj !== 'undefined') {
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      }
    },
    getDistrictName (id) {
      const obj = this.$store.state.CommonService.commonObj.districtList.find(item => item.value === id)
      if (obj !== 'undefined') {
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      }
    },
    getPauroshobaName (id) {
      const obj = this.$store.state.CommonService.commonObj.municipalityList.find(item => item.value === id)
      if (obj !== 'undefined') {
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      }
    },
    getUnionDistrictName (id) {
      const obj = this.$store.state.CommonService.commonObj.upazilaList.find(item => item.value === id)
      if (obj !== 'undefined') {
        return this.getDistrictName(obj.district_id)
      }
    },
    getUnionDivisionName (id) {
      const obj = this.$store.state.CommonService.commonObj.upazilaList.find(item => item.value === id)
      if (obj !== 'undefined') {
        const district = this.$store.state.CommonService.commonObj.districtList.find(item => item.value === obj?.district_id)
        return this.getDivisionName(district?.division_id)
      }
    },
    setWardData (id) {
      const obj = this.$store.state.CommonService.commonObj.wardList.find(item => item.value === id)
      if (obj !== 'undefined') {
        return {
          division_id: obj.division_id,
          district_id: obj.district_id,
          city_corporation_id: obj.city_corporation_id,
          pauroshoba_id: obj.pauroshoba_id,
          upazila_id: obj.upazila_id
        }
      }
    },
    setUnionData (id) {
      const obj = this.$store.state.CommonService.commonObj.unionList.find(item => item.value === id)
      if (obj !== 'undefined') {
        return {
          division_id: obj.division_id,
          district_id: obj.district_id,
          city_corporation_id: 0,
          pauroshoba_id: 0,
          upazila_id: obj.upazila_id
        }
      }
    }
  }
}
</script>
